import React, { FC } from "react"
import DangerouslySetInnerHtml from "components/common/DangerouslySetInnerHtml/DangerouslySetInnerHtml"
import { IImageSection } from "./model"
import "./ImageSection.scss"

const ImageSection: FC<IImageSection> = ({ title, description, image }) => (
  <div className="image-section container">
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-10">
        <p className="image-section__title">{title}</p>
        <DangerouslySetInnerHtml html={description} />
        <img
          className="image-section__img"
          src={image.fallbackUrl}
          alt={image.alt}
        />
      </div>
    </div>
  </div>
)

export default ImageSection
