import React, { FC } from "react";
import { ISlider } from "./model";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import useScreenRecognition from "gatsby-theme-nurofen/src/hooks/useScreenRecognition/useScreenRecognition";
import './Slider.scss';

const Slider: FC<ISlider> = ({ data }) => {

    const { isMobile } = useScreenRecognition();

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 2444, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 768 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 425 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    
    return (
        <div className="slider-container">
            <Carousel responsive={responsive}
                infinite={true}
                autoPlay={true}
                showDots={true}
                autoPlaySpeed={5000}
                className="slider-container__carousel"
            >
            {
                data.map(image =>                                 
                    <div>
                        <img
                        className="slider-container__img" 
                        src={ isMobile ? image.properties.imageMobil.fallbackUrl : image.properties.imageDesktop.fallbackUrl}
                        alt={ isMobile ? image.properties.imageMobil.altText : image.properties.imageDesktop.altText } />
                    </div>
                )
            }
            </Carousel>
        </div>
    )
}

export default Slider;