import {
  IIllustration,
  IPropsSymptomsIllustrations,
  IUmbracoSymptomsListItemProperties,
  IUmbracoSymptomsListMainProperties,
} from './model';

export const symptomsListDefaultData: IPropsSymptomsIllustrations = {
  illustrations: [],
};

const parseSymptomsListItems = (param: IUmbracoSymptomsListItemProperties[]): IIllustration[] => {
  return param.map((item: IUmbracoSymptomsListItemProperties) => {
    const link = item.properties.symptomDataLink?.[0];

    return {
      title: item.properties.symptomTitle || link?.name,
      path: link?.url,
      image: item.properties.symptomDataImage,
      imageAlt: item.properties.symptomDataImageAlt,
      description: item.properties.symptomDescription,
    };
  });
};

export const parseSymptomsList = (
  param: IUmbracoSymptomsListMainProperties
): IPropsSymptomsIllustrations => {
  const headingGroupProps =
    param.symptomsHeadingGroup?.length && param.symptomsHeadingGroup[0].properties;
  const result: IPropsSymptomsIllustrations = {
    illustrations: param.symptomsListItems
      ? parseSymptomsListItems(param.symptomsListItems)
      : symptomsListDefaultData.illustrations,
    headingGroup: headingGroupProps
      ? {
          showDescriptionOnMobile: headingGroupProps.showDescriptionOnMobile,
          symptomsDescription: headingGroupProps.symptomsDescription,
          symptomsHeadingLg: headingGroupProps.symptomsHeadingLg,
          symptomsHeadingMd: headingGroupProps.symptomsHeadingMd,
        }
      : undefined,
    symptomsAnchor: param.symptomsAnchor,
  };

  return result;
};
